<template lang="pug">
modal(@close='$emit("close")')
  generic-form#register(endpoint='/_/Register' @submitSuccess='$emit("close")')
    form-header(@cancel='$emit("close")')
      span(v-html='$t("New registration", "registration")')
    form-select-native(name='Step1/RegistrationTypeField' :modelValue='"customerregistration"')
    form-hidden(name='Step1/Source')
    form-input(type='email' size='large' name='Step1/Email')
    form-select-native(name='Step1/Country' :modelValue='"DE"')
    //- form-select-native(name='Step2/Salutation')
    form-hidden(name='Step2/Salutation' :modelValue='"notspecified"')
    div(class='sm:grid sm:grid-cols-2 sm:gap-4')
      form-input(size='large' name='Step2/GivenName')
      form-input(size='large' name='Step2/FamilyName')
    form-input(size='large' type='tel' name='Step2/Phone')
    form-select-native(size='large' name='Step3/CompanyType')
    form-input(size='large' name='Step3/CompanyName')
    form-input(
      size='large'
      name='Step3/Website'
      :note='$t("Wenn Du keine Website hast oder sich Deine Website im Aufbau befindet, gib bitte die URL zu Deinem aktuellsten Social-Media-Profil (Instagram, Houzz etc.) an.")'
    )
    form-checkbox(name='Step3/Confirm')
      span(v-html='$t("Ich habe die {0} und {1} gelesen und bin einverstanden.", "registration", agbTranslations)')
    form-action(name='Register' medium :primary='true')
</template>

<script lang="ts">
import { useTranslation } from '@/plugins/translation'
import { defineComponent, computed } from 'vue'

const FormRegistration = defineComponent({
  setup() {
    const { $t } = useTranslation()
    return {
      agbTranslations: computed(() => [
        `<a class="decoration" href="https://nuucon.com/terms" target="_blank">${$t('AGB')}</a>`,
        `<a class="decoration" href="https://nuucon.com/privacy" target="_blank">${$t('Datenschutzbestimmungen')}</a>`,
      ]),
    }
  },
})
export default FormRegistration
</script>
