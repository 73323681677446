<template lang="pug">
div(class='app-layout flex min-h-[100vh] flex-1 print:block')
  div(v-if='withSidebar' class='z-1001 w-[60px] bg-grey-100 transition-all' :class='{ "-ml-[60px]": !showSidebar }' style='border-right: 0px solid #eee')
    div(class='sticky -top-6 flex flex-col py-8 pl-2 pr-2 print:hidden')
      slot(name='sidebar')
  div(v-image-lazy-container class='flex w-0 flex-1 flex-col transition-all')
    slot(name='main')
  div#app-right
    slot(name='right')
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const AppLayout = defineComponent({
  props: {
    showSidebar: Boolean,
    withSidebar: Boolean,
  },
  setup() {},
})

export default AppLayout
</script>
