import qs from 'qs'

export const getQueryValue = (key: string, removeItemFromQuery: boolean = false): string => {
  const value: string = getQueryObject()[key]
  if (removeItemFromQuery) {
    removeItemsFromQuery([key])
  }
  return value ? `${value}` : ''
}

export const getQueryObject = (): { [s: string]: string } => {
  const queryObject: any = qs.parse(location.search)
  Object.keys(queryObject).forEach((key: string) => {
    try {
      queryObject[key] = JSON.parse(queryObject[key])
    } catch (e) {}
  })
  return queryObject
}

export const removeItemsFromQuery = (keys: string[]): void => {
  const searchParams = new URLSearchParams(location.search)
  keys.forEach((key: string) => searchParams.delete(key))
  window.history.replaceState({}, '', `${location.pathname}?${searchParams.toString()}`)
}

export const isLocalhost: () => boolean = (): boolean => window.location.hostname === 'localhost'
