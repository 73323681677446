<template lang="pug">
modal(size='base' @close='$emit("close")')
  generic-form#FormCustomersAddressEdit(:endpoint='`/_/Customers/${customerId}/Addresses/${id}`' :allowShortcuts='true' @submitSuccess='$emit("close")')
    template(#default='{ configMap }')
      form-header
        span(v-html='$t("Edit address: <em>{0}</em>", "", [configMap.AddressName.value])')
      form-hidden(name='AddressIdentifier' :modelValue='id')
      form-hidden(name='Type' :modelValue='type')
      form-input(name='AddressName' type='input')
      form-input(name='ContactPerson' type='input')
      form-input(name='Street' type='input')
      div(class='sm:grid sm:grid-cols-2 sm:gap-4')
        form-input(name='CityCode' type='input')
        form-input(name='City' type='input')
      form-select-native(name='Country')
      form-input(name='Phone' type='input')
      form-footer
        form-action(:primary='true' name='UpdateAddress')
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const FormCustomersAddressEdit = defineComponent({
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    customerId: { type: String, required: true },
    type: { type: String, required: true },
  },
})
export default FormCustomersAddressEdit
</script>
