<template lang="pug">
modal(size='sm' @close='$emit("close")')
  generic-form(endpoint='/Administration/Translations/*' :allowShortcuts='true' @submitSuccess='$emit("close")')
    template(#default='{ config }')
      form-header(@cancel='$emit("close")')
        span(v-html='config.label')
      form-input(name='Source')
      form-input(name='de')
      form-input(name='en')
      form-footer
        form-action(:primary='true' name='Create')
</template>

<script lang="ts">
export default {}
</script>
