<template lang="pug">
div#modal(v-tooltip-container)
app-layout(v-if='$translationsReady.value' v-tooltip-container :showSidebar='!hidden' :withSidebar='$permissions.canAccessAdminMenu')
  template(#sidebar)
    btn(class='' faded icon='x' data-tooltip-position='right' :data-tooltip='$t("Hide menu")' @click='hidden = true')
    hr(class='my-2 mx-3')
    btn(faded :active='$route.name === "/administration/dashboard"' to='/administration/dashboard' icon='custom-nuucon' data-tooltip-position='right'  data-tooltip='Dashboard')
    btn(faded :active='$route.name === "/administration/registrations"' to='/administration/registrations' icon='book' data-tooltip-position='right'  data-tooltip='Registrierungen')
    btn(faded :active='$route.path.includes("/administration/users")' :to='{ name: "/administration/users" }' icon='users' data-tooltip-position='right' :data-tooltip='$t("Users")')
    btn(faded :active='$route.path.includes("/administration/customers")' :to='{ name: "/administration/customers" }' icon='custom-company' data-tooltip-position='right' :data-tooltip='$t("Customers")')
    btn(faded :active='$route.path.includes("/administration/suppliers")' :to='{ name: "/administration/suppliers" }' icon='truck' data-tooltip-position='right' :data-tooltip='$t("Suppliers")')
    btn(faded :active='$route.name === "/administration/projects"' to='/administration/projects' icon='folder' data-tooltip-position='right'  data-tooltip='Projekte')
    btn(faded :active='$route.name === "/administration/inquiries"'  to='/administration/inquiries' icon='custom-inquiry' data-tooltip-position='right' :data-tooltip='$t("Inquiries")')
    hr(class='my-2 mx-3')
    btn(faded :active='$route.path.includes("/administration/products")' :to='{ name: "/administration/products-import/" }' icon='shopping-bag' data-tooltip-position='right' :data-tooltip='$t("Product import")')
    btn(faded :active='$route.path.includes("/administration/advanced")' :to='{ name: "/administration/advanced/" }' icon='code' data-tooltip-position='right' :data-tooltip='$t("Advanced")')
    btn(faded :active='$route.path.includes("/administration/tools")' :to='{ name: "/administration/tools/" }' icon='tool' data-tooltip-position='right' :data-tooltip='$t("Tools")')
  template(#main)
    template(v-if='($user.isLoggedIn && $user.ready) || $route.query.forceLogin')
      helpcenter(v-if='$flags.ENABLE_HELPCENTER')
      app-header(:showAdminMenuButton='hidden' class='row-fluid' @showAdminMenuButtonClick='hidden = false')
      div( class='flex flex-col flex-1')
        //- @TODO: this currently causes "missing required param error"
        router-view(v-slot="{ Component, route}")
          keep-alive(include='ProductsId,ProductsIndex,ProjectsIndex,InquiriesIndex,InquiriesId,Clipboard,Dashboard,User,Customer') 
            component(:is="Component" v-bind='route.params')
      message-list(:messages='$messages.value' @close='(key: string) => $msg.close(key)')
      div#content

      modal-provider
      modal-confirm-provider
      modal-onboarding(v-if='$user && !$user.impersonator && !$flags.DISABLE_PRODUCT_TOUR' v-model='onboardingVisible' @close='$utils.routeQueryRemove("reset-onboarding")')

</template>

<script setup lang="ts">
import { emitter } from './common'
import { useFeatureFlags } from './plugins/FeatureFlags'
import { useModals } from './plugins/Modals'
import { useUser } from './plugins/User'
import { useUtils } from './plugins/Utils'
import { useTranslation } from './plugins/translation'
import { usePlatformUpdates } from './stores'
import { type IFormResponseInfo } from '@/components/GenericForm/types'
import { useMessages } from '@/components/Message'
import { useOnSuccess, useUserSettingsValue } from '@/composables/'
import { type IInquiry } from '@/pages/inquiries/types'
import type { IProjectData } from '@/pages/projects/types'
import { useLocalStorage, useScriptTag } from '@vueuse/core'
import Cookies from 'js-cookie'
import { watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const $user = useUser()
const $route = useRoute()
const $utils = useUtils()
const $router = useRouter()
const { $msg } = useMessages()
const { $t } = useTranslation()
const $modals = useModals()
const $flags = useFeatureFlags()

if (!$flags.DISABLE_INTERCOM) {
  useScriptTag('/third-party.js')
}

/**
 * Login
 */
const updateLoggedInState = (loggedIn: boolean) => {
  if (loggedIn) {
    document.body.classList.add('isLoggedIn')
  } else {
    window.location.href = '/login.html'
  }
}

/**
 * Admin Menu
 */
const hidden = useLocalStorage('hideAdminMenu', false)
watch(() => $user.isLoggedIn, updateLoggedInState)
updateLoggedInState($user.isLoggedIn)

/**
 * Onboarding Modal
 */
const onboardingVisible = useUserSettingsValue('onboardingVisible', true)
const resetOnboarding = () => $route.query['reset-onboarding'] == 'true' && (onboardingVisible.value = true)
watch(() => $route.query, resetOnboarding)
resetOnboarding()

/**
 * Platform Updates
 */
if (!$flags.DISABLE_PRODUCT_TOUR && !$user.impersonator) {
  const store = usePlatformUpdates()
  watch(
    () => $route.query,
    () => {
      if ($route.query['reset-platform-updates']) {
        store.reset()
        $utils.routeQueryRemove('reset-platform-updates')
      }
    },
  )

  setTimeout(
    () => !onboardingVisible.value && store.unseenEntryDates.length > 0 && $modals.open('modal-platform-updates', {}),
    400,
  )
}

/**
 * Global Messages
 */
useOnSuccess(['AddProductToProject', 'AddOrEditPconProduct/*/'], (data?: IFormResponseInfo) => {
  const project: IProjectData | undefined = data?.response?.data?.project || data?.response?.data
  const inquiry: IInquiry | undefined = data?.response?.data?.inquiry
  const isSameProject = $route.matched.map((r) => r.name).includes('project') && $route.params.id === project?.id
  if (isSameProject) {
    $msg.success($t('Added product successfully.'))
  } else {
    $msg.info(
      { component: 'project-message-item', props: { project, inquiry } },
      { delay: 8000, size: 'default', closable: true, icon: false },
    )
  }
})

useOnSuccess(['Projects/*/Create'], (data?: IFormResponseInfo) => {
  const project: IProjectData | undefined = data?.response?.data?.project || data?.response?.data
  $msg.info(
    { component: 'project-message-item', props: { project, created: true } },
    { delay: 8000, size: 'default', closable: true, icon: false },
  )
})

emitter.on('routeError', () => {
  $msg.info(
    { component: 'message-update-application', props: {} },
    { delay: 80000, size: 'large', closable: true, icon: true },
  )
})

/**
 * Cookie Redirect
 */
onMounted(() => {
  const redirectHash = Cookies.get('redirectHash')
  if (redirectHash && redirectHash !== '#/') {
    $router.push(redirectHash.replace('#/', ''))
    Cookies.remove('redirectHash')
  }
})
</script>
