<template lang="pug">
modal(size='sm' @close='$emit("close")')
  generic-form(:id='data.id' :endpoint='data.baseUrl' @submitSuccess='$emit("close")')
    template(#default='{ config }')
      form-header
        | {{ data.label || config.label }}
      component(v-bind='item.bindings' :is='item.type' v-for='item in data.fields' :key='item.name' :name='item.name')
      form-footer
        form-action(v-for='action in actions' :key='action.name' type='secondary' :name='action.name' :label='action.label' :primary='action.primary')
</template>

<script lang="ts">
import { isArray } from 'radash'
import { computed, defineComponent, type PropType } from 'vue'

export interface IGenericFormFieldSettings {
  name: string
  type: string
  bindings: any
}

export interface IFormAction {
  name: string
  label?: string
  primary?: boolean
}

export interface IGenericFormSettings {
  baseUrl: string
  label?: string
  fields: IGenericFormFieldSettings[]
  data: any
  id: string
  action: IFormAction[] | string
}

const FormGeneric = defineComponent({
  props: {
    data: { type: Object as PropType<IGenericFormSettings>, required: true },
  },
  setup(props) {
    return {
      actions: computed<IFormAction[]>(() =>
        isArray(props.data.action)
          ? (props.data.action as IFormAction[])
          : [{ name: props.data.action } as IFormAction],
      ),
    }
  },
})
export default FormGeneric
</script>
