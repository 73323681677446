<template lang="pug">
btn(faded large-icon :data-tooltip='$t("Clipboard")' to='/clipboard' :active='$route.name === "clipboard"' data-test='btn-nav-clipboard' icon='custom-clipboard' class='clipboard-nav-button__button')
</template>

<script lang="ts">
import { useClipboardStore } from './store'
import { defineComponent } from 'vue'

const ClipboardNavButton = defineComponent({
  setup() {
    const store = useClipboardStore()
    store.loadItems()
  },
})
export default ClipboardNavButton
</script>
<style lang="stylus">
@import '../../styles/variables.styl'

.clipboard-nav-button
  margin-right: 2px
  position: relative
  &__avatar
    position: absolute
    right: -6px
    top: -2px
    z-index: 99
  p
    margin: 0 !important
    font-size: 13px
    color: #fff
    font-family: $font-default
</style>
