<template lang="pug">
div(class='row max-w-[500px]')
  template(v-if='!error || debug')
    h2(class='font-lato text-2xl font-thin') {{ $t('Hello {0},', 'registration-confirmation', [fullName]) }}
    p(class='mb-3 font-lato text-2xl font-black')
      | {{ $t('your email has been successfully verified.', 'registration-confirmation') }}
    p(class='')
      | {{ $t('Nuucon is for companies and design professionals only. We will check your registration and get back to you shortly.', 'registration-confirmation') }}
    div(class='mt-4 flex items-center gap-4')
      btn(secondary icon='message-square' @click='chatWithUs') {{ $t('Ask a question', 'registration-confirmation') }}
      div
        btn(class='mr-3' secondary icon='calendar' @click='$thirdParty.makeAppointment()') {{ $t('Make an appointment', 'registration-confirmation') }}
      span
        a(class='flex items-center' href='tel:+40228582100')
          icon(name='phone' class='mr-2')
          span +49 40 228 582 100

  template(v-else)
    p(class='') Sorry, diese Seite ist nicht mehr gültig.
</template>

<script setup lang="ts">
import { useTranslation } from '@/plugins/translation'
import { openIntercomChat } from '@/utilities/ThirdParty'
import { useScriptTag } from '@vueuse/core'
import Axios, { type AxiosResponse } from 'axios'
import { ref, onMounted } from 'vue'

const { $t } = useTranslation()
const fullName = ref('')
const error = ref(false)
const loading = ref(true)
const debug = ref(false)
useScriptTag('/third-party.js')

onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search)
  const id = urlParams.get('id')
  debug.value = !!urlParams.get('debug')
  Axios.get('/_/ConfirmRegistration', { params: { id } })
    .then((response: AxiosResponse<any>) => {
      if (response.data.confirmed) {
        fullName.value = response.data.fullName
      } else {
        error.value = true
      }
      loading.value = false
    })
    .catch(() => {
      error.value = true
      loading.value = false
    })
})
const chatWithUs = () => openIntercomChat($t('Hi, I would like to..', 'registration-confirmation'))
</script>
