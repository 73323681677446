import { type App, type Plugin, type Ref, ref, computed, inject } from 'vue'

export interface IHelpcenterPlugin {
  currentId: Ref<string>
  fromId: Ref<string | undefined>
  isOpen: Ref<boolean>
  open: (id?: string, fromId?: string) => void
  close: () => void
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $helpcenter: IHelpcenterPlugin
  }
}

export const useHelpcenter = () => {
  const $helpcenter = inject<IHelpcenterPlugin>('$helpcenter')!
  return $helpcenter
}

const HelpcenterPlugin: Plugin = {
  install(app: App) {
    const $route = app.config.globalProperties.$router?.currentRoute
    const $utils = app.config.globalProperties.$utils
    const open = ref(false)
    const fromId = ref('')
    const currentId = ref('')
    const $helpcenter: IHelpcenterPlugin = {
      open: (id?: string, fromIdValue?: string) => (
        (open.value = true),
        (currentId.value = id! || 'default'),
        (fromId.value = fromIdValue!),
        $utils.routeQueryAdd({ helpcenter: currentId.value, helpcenterFrom: fromIdValue }, false, false)
      ),
      close: () => ((open.value = false), $utils.routeQueryRemove('helpcenter')),
      currentId: computed(() => currentId.value || $route.value.query.helpcenter),
      fromId: computed(() => fromId.value || $route.value.query.helpcenterFrom),
      isOpen: computed(() => open.value || !!$route.value.query.helpcenter),
    }

    app.config.globalProperties.$helpcenter = $helpcenter
    app.provide<IHelpcenterPlugin>('$helpcenter', $helpcenter)
  },
}
export default HelpcenterPlugin
