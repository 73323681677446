<template lang="pug">
time(:datetime='datetime') {{ formattedDate }}
</template>

<script lang="ts">
import { useTranslatedTimeAgo } from '@/composables/'
import { useDateFormat } from '@vueuse/core'
import { computed, defineComponent } from 'vue'

const FormattedDate = defineComponent({
  props: {
    datetime: { type: String, required: true },
    formatStr: { type: String, default: 'dddd, DD. MMMM, YYYY' },
  },
  setup(props) {
    const timeAgo = useTranslatedTimeAgo(new Date(props.datetime))
    const dateFormat = useDateFormat(new Date(props.datetime), props.formatStr)
    return {
      formattedDate: computed(() => `${timeAgo.value} – ${dateFormat.value}`),
    }
  },
})

export default FormattedDate
</script>
