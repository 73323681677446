<template lang="pug">
div(class='slider-content')
  slider(:options='sliderConfig')
    slot
</template>

<script lang="ts">
import CdnImage from './CdnImage.vue'
import Slide from './Slide.vue'
import Slider from '@/components/Slider.vue'
import { type Options } from '@splidejs/splide'
import { defineComponent } from 'vue'

export const SlideCdnImage = defineComponent({
  components: { Slide, CdnImage },
  props: {
    src: String,
    caption: String,
  },
  template: `<slide><cdn-image rounded :src='src'></cdn-image><em v-html='caption'></em></slide>`,
})

const SliderContent = defineComponent({
  components: { Slider },
  setup() {
    return {
      sliderConfig: {
        gap: 30,
        type: 'loop',
        autoHeight: true,
        perPage: 1,
        perMove: 1,
        lazyLoad: false,
        pagination: true,
        fixedWidth: '100%',
      } as Options,
    }
  },
})
export default SliderContent
</script>
<style lang="stylus">
@import '../styles/variables.styl'

.slider-content
  --slider-dot-size: 5px
  img
    width: 100%
  @media (min-width: $breakpoint-md)
    --slider-arrow-offset: 10px
  .splide__pagination
    justify-content: flex-start
  .splide__slide
    transition: opacity .3s ease
    &:not(.is-active)
      opacity: 0.5
  .splide__arrows
    z-index: 9
    > *
      opacity: 0
      transition: opacity .2s ease
    ~/:hover&
      > *
        opacity: 1
</style>
