import AppComponent from './App.vue'
import './boot'
import './common'
import { emitter, ThirdParty } from './common'
import AddClickHandlers from './directives/AddClickHandlers'
import AddElementAttributes from './directives/AddElementAttributes'
import AddStyle from './directives/AddStyle'

/**
 * Directives
 */
import ClickPost from './directives/ClickPost'
import ContactLink from './directives/ContactLink'
import FocusTrap from './directives/FocusTrap'
import GtmEvent from './directives/GtmEvent'
import TooltipContainer from './directives/TooltipContainer'
import LoginView from './pages/components/LoginView.vue'
import Registration from './pages/components/Registration.vue'
import RegistrationConfirmationView from './pages/components/RegistrationConfirmationView.vue'
import ResetPasswordView from './pages/components/ResetPasswordView.vue'
import SetInitialPasswordView from './pages/components/SetInitialPasswordView.vue'
import ProductDetailContent from './pages/products/components/ProductDetailContent.vue'
import ConfirmPlugin from './plugins/Confirm'

/**
 * Plugins
 */
import EscapeKeyPlugin from './plugins/EscapeKey'
import FeatureFlagsPlugin from './plugins/FeatureFlags'
import HelpcenterPlugin from './plugins/Helpcenter'
import ModalsPlugin from './plugins/Modals'
import PermissionsPlugin from './plugins/Permissions'
import TourPlugin from './plugins/Tour'
import UserPlugin, { getUserDataFromCookie } from './plugins/User'
import UtilsPlugin from './plugins/Utils'
import TranslationPlugin from './plugins/translation'
import router from './router'

/**
 * Tracking
 */
import './tracking'
import { registerComponentsFromModules, registerComponents, registerDirectives } from './utilities/Components'
import Env from './utilities/Env'
import FormattedDate from '@/components/FormattedDate.vue'
import { Components } from '@/components/GenericForm'
import { MessagePlugin } from '@/components/Message'
import { type MessageType } from '@/components/Message'
import MessageErrorItem from '@/components/MessageErrorItem.vue'
import MessageUpdateApplication from '@/components/MessageUpdateApplication.vue'
import ProductItem from '@/components/ProductItem.vue'
import SliderContent, { SlideCdnImage } from '@/components/SliderContent.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import EmailSignatureView from '@/pages/components/EmailSignatureView.vue'
import ProjectMessageErrorItem from '@/pages/projects/components/ProjectMessageErrorItem.vue'
import ProjectMessageItem from '@/pages/projects/components/ProjectMessageItem.vue'
import '@/styles/index.css'
import '@/styles/main.styl'

/**
 * Sentry Integration
 */
import * as Sentry from '@sentry/vue'
import { AxiosError, type AxiosResponse } from 'axios'
import Axios from 'axios-observable'
import Cookies from 'js-cookie'
import { createPinia } from 'pinia'
import 'virtual:svg-icons-register'
import { createApp, defineAsyncComponent } from 'vue'

/**
 *  Register not explicitely referenced or dynamically generated components
 *  that are not automatically registered and imported by "unplugin-vue-components"
 */
import { Collapse as CollapseContent } from 'vue-collapsed'

const isMainApp = !!document.getElementById('app')

/**
 * Create app with AppComponent or empty
 */
const app = isMainApp ? createApp(AppComponent) : createApp({})

// only use router and pinia for main app not for login, registration etc
if (isMainApp) {
  console.log('isMainApp')
  app.use(router)
  const pinia = createPinia()
  app.use(pinia)
}

/**
 *  Glob import and register forms and modals
 */
registerComponentsFromModules(
  app,
  import.meta.glob(['@/pages/projects/forms/*.vue', '@/forms/*.vue', '@/pages/**/forms/*.vue', '@/modals/*.vue'], {
    eager: true,
  }),
)

registerComponents(app, {
  ...Components,
  CollapseContent,
  VideoPlayer,
  FormattedDate,
  ProductItem,
  SliderContent,
  SlideCdnImage,
  ProductDetailContent,
  ProjectMessageItem,
  ProjectMessageErrorItem,
  MessageErrorItem,
  MessageUpdateApplication,
  Registration,
  LoginView,
  ResetPasswordView,
  SetInitialPasswordView,
  RegistrationConfirmationView,
  EmailSignatureView,
})

/**
 * Manually registered components
 */
app.component(
  'PconProductConfigurator',
  defineAsyncComponent(() => import('./PconProductConfigurator.vue')),
)

app.use(EscapeKeyPlugin)

app.use(FeatureFlagsPlugin)

app.use(PermissionsPlugin)

app.use(TranslationPlugin)

app.use(UtilsPlugin)

app.use(UserPlugin)

app.use(TourPlugin)

app.use(MessagePlugin)

app.use(ModalsPlugin)

app.use(ConfirmPlugin, app.config.globalProperties.$t)

app.use(HelpcenterPlugin)

registerDirectives(app, {
  ClickPost,
  ContactLink,
  ...GtmEvent,
  AddElementAttributes,
  AddClickHandlers,
  FocusTrap,
  ...AddStyle,
  TooltipContainer,
})

/**
 * ENV
 */
app.config.globalProperties.$env = Env

/**
 * Third party
 */
app.config.globalProperties.$thirdParty = ThirdParty
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $thirdParty: typeof ThirdParty
  }
}
/**
 * Event Emitter
 */
app.config.globalProperties.$emitter = emitter

/**
 * Mount app on #app for applications; and .vue-container for login etc.
 */
app.mount('#app, .vue-container')

/**
 * Clear SessionStorage when commit hash changed.
 */
const commitHashStorageKey = 'commitHash'
const commitHashFromStorage = window.localStorage.getItem(commitHashStorageKey)
if (commitHashFromStorage !== Env.commitHash) {
  window.sessionStorage.clear()
  window.localStorage.setItem(commitHashStorageKey, Env.commitHash)
}

if (!Env.isLocalhost) {
  Sentry.init({
    app,
    dsn: 'https://77858a0caaec47ef948c8257379664ce@sentry.io/1793831',
    environment: Env.isProductionBuild ? 'production' : 'test',
  })
  Sentry.setUser({ id: getUserDataFromCookie()?.id, username: getUserDataFromCookie()?.username })
}

/**
 * Initial Notifications
 */
if (Cookies.get('notifications')) {
  try {
    const { type, message } = JSON.parse(Cookies.get('notifications')!)
    setTimeout(() => app.config.globalProperties.$msg[type as MessageType](message, { delay: 5000 }), 600)
    Cookies.remove('notifications')
  } catch (e) {
    console.warn('notifications cookie not valid json')
  }
}

/**
 * Axios Interceptors
 */
Axios.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data.redirect) {
      window.location.href = response.data.redirect
      // store notifications cookie to be displayed after redirect
      if (response.data.notification) {
        Cookies.set('notifications', JSON.stringify(response.data.notification))
      }
    } else if (response.data.command === 'reload') {
      window.location.reload()
    }
    if (response.data.notification && app.config.globalProperties.$msg) {
      const { type, message } = response.data.notification
      app.config.globalProperties.$msg[type as MessageType](message, { delay: 12000, icon: true, closable: true })
    }
    return response
  },
  (error: AxiosError<any>) => {
    const errorMap = new Map()
    errorMap.set(500, 'Internal Server Error')
    errorMap.set(413, 'Payload Too Large')
    errorMap.set(415, 'Unsupported Media Type')
    if (errorMap.has(error.response?.status)) {
      const errorData = error.response?.data
      if (typeof errorData === 'string') {
        app.config.globalProperties.$msg.error(error.response?.data as string, {
          delay: 12000,
          icon: true,
          closable: true,
        })
      } else {
        const { type, message } = error.response?.data.notification
        app.config.globalProperties.$msg[type as MessageType](message, { delay: 12000, icon: true, closable: true })
      }
    }
    const payload = error.config?.data instanceof FormData ? Object.fromEntries(error.config?.data?.entries()) : {}
    console.error('AxiosError', error, payload)
    Sentry.withScope((scope) => {
      scope.setTag('request-id', error.response?.headers['x-thor-request-id'])
      Sentry.captureException(JSON.stringify(error.response), {
        contexts: { payload: { values: JSON.stringify(payload) } },
      })
    })
    return Promise.reject(error)
  },
)

emitter.emit('ready')

export default app
