<template lang="pug">
div(class='content-nav')
  control-input(v-model='term' icon='search' rounded :placeholder='placeholder')
  div(v-if='term.length >= minMatchCharLength' class='content-nav__group')
    a(
      v-for='{ item, matches } in results'
      :key='item.id'
      href='#'
      :data-test-content-item='item.id'
      class='content-nav__item'
      @click.prevent='$emit("onSelect", item.id)'
      v-html='highlight(item.title, matches[0].indices)'
    )
    empty(v-if='results.length === 0')
  template(v-else)
    div(v-for='group in data' :key='group.id' class='content-nav__group')
      div(class='content-nav__groupTitle') {{ group.title }}
      a(v-for='item in group.children' :key='item.id' href='#' :data-test-content-item='item.id' class='content-nav__item' @click.prevent='$emit("onSelect", item.id)') {{ item.title }}
  div(v-if='term.length <= minMatchCharLength')
    slot
</template>
<script lang="ts">
import { type IHtmlJsonItem } from './Helpcenter.vue'
import { highlight } from '@/composables/'
import { useSessionStorage } from '@vueuse/core'
import { useFuse } from '@vueuse/integrations/useFuse'
import { computed, defineComponent, ref, type PropType } from 'vue'

const ContentNav = defineComponent({
  props: {
    data: { required: true, type: Object as PropType<IHtmlJsonItem[]> },
    minMatchCharLength: { default: 2, type: Number },
    id: { type: String, default: 'contentNav' },
    placeholder: { type: String, default: 'Search..' },
  },
  setup(props) {
    const selected = ref('')
    const dataFlat = computed(() => props.data.flatMap((d) => d.children))
    const term = useSessionStorage(props.id, '')
    const { results } = useFuse(term, dataFlat, {
      fuseOptions: {
        keys: ['title'],
        includeScore: true,
        includeMatches: true,
        minMatchCharLength: props.minMatchCharLength,
      },
    })
    return {
      term,
      highlight,
      results,
      selected,
    }
  },
})

export default ContentNav
</script>
<style lang="stylus">
.content-nav
  padding-bottom: 20px
  &__groupTitle
    color: var(--color-text-light)
    text-transform: uppercase
    letter-spacing: 0.08em
    font-size: 13px
    margin-bottom: 5px
  &__group
    padding: 20px 0 6px
  &__item
    padding: 6px 0
    line-height: 1.5
    font-size: 16px
    display: block
</style>
