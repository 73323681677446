<template lang="pug">
modal(size='sm' @close='$emit("close")')
  generic-form(ref='form' :endpoint='`/Administration/Products/${id}/AssetOrder`' :allowShortcuts='true' @submitSuccess='$emit("close")' @updateConfig='updateConfig')
    template(#default='{ config }')
      form-header(@cancel='$emit("close")')
        span(v-html='config.label')
      div(class='bleed')
        custom-data-table(:items='itemsLocal' class='mb-4' :config='{ image: { label: "Image", classes: "image" }, fileName: { label: "Filename" } }' itemKey='image' small :showPagination='false')
          template(#fileName='{ item }')
            | {{ item.image }}
          template(#image='{ item }')
            img(v-image-lazy :data-src='item.image' data-cloudinary='t_xs' class='inline-block w-[60px]')
          template(#after='{ item, index }')
            div(class='flex gap-3') 
              btn(small faded :disabled='index === 0' :data-tooltip='$t("Move Up")' icon='arrow-up' @click='move(index, -1)')
              btn(small faded :disabled='index === itemsLocal.length - 1' :data-tooltip='$t("Move Down")' icon='arrow-down' @click='move(index, 1)')
              btn(small faded :data-tooltip='$t("Remove Image")' icon='trash' @click='remove(item.image)')
      form-footer
        template(#secondary)
          file-upload(:url='`/Administration/Products/${id}/UploadAsset`' name='Asset' secondary :multiple='true' @success='onFileUpload')
            | {{ $t('Add Images') }}
        form-action(:primary='true' name='Update')
      form-hidden(name='Assets' :modelValue='itemsFlat')
</template>

<script lang="ts">
import { type IGenericFormConfig } from '@/components/GenericForm/types'
import { arrayMoveImmutable } from 'array-move'
import { computed, defineComponent, ref } from 'vue'

export interface IImage {
  image: string
}

const FormProductImages = defineComponent({
  props: {
    id: String,
  },
  setup() {
    const form = ref<any>(null)
    const itemsLocal = ref<IImage[]>([])

    return {
      form,
      itemsLocal,
      updateConfig: (c: { [id: string]: IGenericFormConfig }) =>
        (itemsLocal.value = (c?.Assets?.value as string[]).map((image: string) => ({ image })) || []),
      onFileUpload: () => form.value?.fetchConfig(),
      move: (index: number, direction: number) =>
        (itemsLocal.value = arrayMoveImmutable(itemsLocal.value, index, index + direction)),
      itemsFlat: computed(() => itemsLocal.value.map((item: any) => item.image)),
      remove: (image: string) => (itemsLocal.value = itemsLocal.value.filter((img: IImage) => img.image !== image)),
    }
  },
})
export default FormProductImages
</script>
