<template lang="pug">
generic-form#GenericDeleteForm(:endpoint='data.endpoint' @submitSuccess='$emit("close"), data.redirect && $router.replace(data.redirect)')
  template(#default='{ config }')
    form-header(@cancel='$emit("close")')
      div(class='thin' v-html='data.title || $t("<b>Delete:</b> {0}", "", [config.label])')
      template(#below)
        notification(v-if='data.infotext' class='mt-2' medium) {{ data.infotext }}
    form-footer
      form-action(type='danger' :primary='true' name='Delete')
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'

export interface IDeleteFormProperties {
  redirect: string
  infotext?: string
  title?: string
  endpoint: string
}
const FormDelete = defineComponent({
  props: {
    data: { type: Object as PropType<IDeleteFormProperties>, required: true },
  },
})
export default FormDelete
</script>
