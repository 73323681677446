<template lang="pug">
modal-container(enableBodyScroll)
  modal(v-if='visible' v-slot='{ close }' :keyboard='true' size='sm' style='--modal-padding-top: 0; --modal-padding-left: 0; --modal-padding-right: 0' v-bind='$attrs' @close='onClose')
    btn(plain icon='x' class='!absolute right-4 top-4 z-50' icon-right data-id='btn-close-modal' @click='close') 
      div(class='pr-2 text-xs font-bold' data-test='btn-close-modal') {{ $t('close') }}
    div(class='overflow-hidden rounded-t-xl')
      drilldown(ref='drilldown' v-model='activeItem')
        slot 
        template(#after='{ currentIndex, children, prev, next }')
          div(class='px-8')
            div(class='relative h-[50px]')
              transition(name='fade')
                div(v-if='currentIndex === 0' class='absolute flex w-full justify-center')
                  btn(icon='arrow-right' medium icon-right block class='max-w-[300px]' @click='next') {{ $t('start') }}
                div(v-else class='absolute flex w-full gap-5')
                  btn(medium secondary icon='arrow-left' @click='prev') 
                  btn(medium :icon='isNotLastItem ? "arrow-right" : "check"' @click='isNotLastItem ? next() : close()') 
                    span(v-if='!isNotLastItem') {{ $t('ready') }}
                  div(class='flex-1')
                  dot-nav(v-model='activeItem' :num='children.length' class='mt-4 w-auto justify-center')
</template>

<script setup lang="ts">
import DotNav from './DotNav.vue'
import Drilldown from './Drilldown.vue'
import ModalContainer from './ModalContainer.vue'
import { onKeyStroke } from '@vueuse/core'
import { ref, computed } from 'vue'

defineOptions({ inheritAttrs: false })
const emit = defineEmits(['close'])
const visible = defineModel<boolean>()
const activeItem = ref(0)
const drilldown = ref<InstanceType<typeof Drilldown> | null>(null)
onKeyStroke('ArrowRight', () => drilldown.value?.next())
onKeyStroke('ArrowLeft', () => drilldown.value?.prev())
const isNotLastItem = computed(
  () => drilldown.value && drilldown.value!.currentIndex < drilldown.value!.children.length - 1,
)
const onClose = () => ((visible.value = false), emit('close'))
</script>
