<template lang="pug">
modal(size='sm' admin @close='$emit("close")') 
  generic-form(endpoint='/Administration/Customers/*' :allowShortcuts='true' @submitSuccess='$emit("close")')
    template(#default)
      form-header {{ $t('Neue Organisation') }}
      form-input(name='Name')
      form-select-native(name='ResponsibleOperator')
      form-footer
        form-action(:primary='true' name='Create')
</template>

<script lang="ts">
export default {}
</script>
