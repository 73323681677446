<template lang="pug">
modal(size='xl' mode='drawer' @close='$emit("close")')
  form-header(@cancel='$emit("close")')
    | ImportLog
  iframe(:src='url' class='w-full flex-1 overflow-scroll')
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const FormImportLog = defineComponent({
  props: {
    url: String,
  },
})
export default FormImportLog
</script>
