<template lang="pug">
div
  div(v-for='(profile, index) in profiles' :key='index' class='grid-cols-2 gap-4 sm:grid' :data-signature-index='index')
    div
      div(class='gap-4 sm:grid sm:grid-cols-2')
        control-label(label='Given Name' :floating='true')
          control-input(v-model='profile.givenName')
        control-label(label='Family Name' :floating='true')
          control-input(v-model='profile.familyName')
      control-label(label='Position' :floating='true')
        control-input(v-model='profile.position')
      div(class='gap-4 sm:grid sm:grid-cols-2')
        control-label(label='Phone Number' :floating='true')
          control-input(v-model='profile.phone')
        control-label(label='Mobile Phone Number' :floating='true')
          control-input(v-model='profile.mobile')
      control-label(label='E-Mail Adress' :floating='true')
        control-input(v-model='profile.email')
      div(class='gap-4 sm:grid sm:grid-cols-2')
        control-label(label='Instagram Adress' :floating='true')
          control-input(v-model='profile.instagram')
        control-label(label='LinkedIn' :floating='true')
          control-input(v-model='profile.linkedin')
      div(class='gap-4 sm:grid sm:grid-cols-2')
        control-label(label='Whatsapp' :floating='true')
          control-input(v-model='profile.whatsapp')
        div(class='flex items-center')
          a(href='https://faq.whatsapp.com/smba/account-and-profile/about-short-links/?lang=de' target='_blank') Wie erstellt man einen WhatsApp-Link
      div(class='gap-4 sm:grid sm:grid-cols-2')
        control-label(label='Image Url' :floating='true')
          control-input(v-model='profile.imageUrlA')
        control-label(style='max-width: 200px' label='Image Filename' :floating='true')
          control-input(v-model='profile.imageUrlB')
    div(style='width: 400px')
      group(label='Preview' :bordered='true')
        table(cellpadding='0' cellspacing='0' style='vertical-align: -webkit-baseline-middle; width: auto; font-size: medium; font-family: sans-serif' class='signature')
          tbody
            tr
              td
                table(cellpadding='0' cellspacing='0' style='vertical-align: -webkit-baseline-middle; font-family: sans-serif')
                  tbody
                    tr
                      td(v-if='profile.imageUrlA || profile.linkedin || profile.instagram' style='vertical-align: top')
                        table(cellpadding='0' cellspacing='0' style='vertical-align: -webkit-baseline-middle; font-family: sans-serif')
                          tbody
                            tr
                              td(style='text-align: center')
                                div(style='border-radius: 50%; width: 100px; height: 100px; overflow: hidden')
                                  img(:src='profile.imageUrlA + profile.imageUrlB' width='100' style='max-width: 110px; display: block')
                            tr
                              td(height='10')
                            tr
                              td(style='text-align: center')
                                a(v-if='profile.whatsapp' :href='profile.whatsapp' color='#6A78D1' style='display: inline-block; padding: 0px' target='_blank')
                                  img(
                                    src='https://res.cloudinary.com/nuucon-io/image/upload/f_auto,w_30/v1591107211/email/assets/whatsapp.png'
                                    alt='whatsapp'
                                    color='#6A78D1'
                                    height='30'
                                    style='max-width: 135px; display: block'
                                  )
                                a(v-if='profile.linkedin' :href='profile.linkedin' color='#6A78D1' style='display: inline-block; padding: 0px' target='_blank')
                                  img(
                                    src='https://res.cloudinary.com/nuucon-io/image/upload/f_auto,w_30/v1591107211/email/assets/linked-in.png'
                                    alt='linkedin'
                                    color='#6A78D1'
                                    height='30'
                                    style='max-width: 135px; display: block'
                                  )
                                a(v-if='profile.instagram' :href='profile.instagram' color='#6A78D1' style='display: inline-block; padding: 0px' target='_blank')
                                  img(
                                    src='https://res.cloudinary.com/nuucon-io/image/upload/f_auto,w_30/v1591107210/email/assets/instagram.png'
                                    alt='instagram'
                                    color='#6A78D1'
                                    height='30'
                                    style='max-width: 135px; display: block'
                                  )
                      td(v-if='profile.imageUrlA || profile.linkedin || profile.instagram' width='30')
                        div(style='box-sizing: border-box')
                      td(style='padding: 0px; vertical-align: middle')
                        h3(color='#000000' style='font-family: sans-serif; margin: 0px; font-size: 13px; color: rgb(0, 0, 0)').
                          {{profile.givenName}} {{profile.familyName}}
                        p(color='#000000' style='font-family: sans-serif; margin: 0px; color: rgb(0, 0, 0); font-size: 13px; line-height: 16px; margin: 2px 0 10px').
                          {{profile.position}}
                        table(cellpadding='0' cellspacing='0' style='color: rgb(255, 255, 255); margin: 0 0 20px; font-family: sans-serif; vertical-align: -webkit-baseline-middle')
                          tbody
                            tr(v-if='profile.phone' height='20' style='vertical-align: middle')
                              td(width='28' style='vertical-align: middle')
                                img(src='https://res.cloudinary.com/nuucon-io/image/upload/f_auto,w_13/v1591107214/email/assets/phone.png' color='#0f0a0b' width='13' style='display: block')
                              td(style='padding: 0px; color: rgb(0, 0, 0)')
                                a(:href='`tel:${profile.phone}`' color='#000000' style='text-decoration-line: none; color: rgb(0, 0, 0); font-size: 13px' target='_blank') {{ profile.phone }}
                            tr(v-if='profile.mobile' height='20' style='vertical-align: middle')
                              td(width='28' style='vertical-align: middle')
                                img(src='https://res.cloudinary.com/nuucon-io/image/upload/f_auto,w_13/v1591107214/email/assets/mobile.png' color='#0f0a0b' width='13' style='display: block')
                              td(style='padding: 0px; color: rgb(0, 0, 0)')
                                a(:href='`tel:${profile.mobile}`' color='#000000' style='text-decoration-line: none; color: rgb(0, 0, 0); font-size: 13px' target='_blank') {{ profile.mobile }}
                            tr(height='20' style='vertical-align: middle')
                              td(width='28' style='vertical-align: middle')
                                table(cellpadding='0' cellspacing='0' style='vertical-align: -webkit-baseline-middle; font-family: sans-serif')
                                  tbody
                                    tr
                                      td(style='vertical-align: bottom')
                                        img(src='https://res.cloudinary.com/nuucon-io/image/upload/f_auto,w_13/v1591107214/email/assets/mail.png' color='#0f0a0b' width='13' style='display: block')
                              td(style='padding: 0px')
                                a(:href='`mailto:${profile.email}`' color='#000000' style='text-decoration-line: none; color: rgb(0, 0, 0); font-size: 13px' target='_blank') {{ profile.email }}
                            tr(height='20' style='vertical-align: middle')
                              td(width='28' style='vertical-align: middle')
                                img(src='https://res.cloudinary.com/nuucon-io/image/upload/f_auto,w_13/v1591107214/email/assets/link.png' color='#0f0a0b' width='13' style='display: block')
                              td(style='padding: 0px')
                                a(
                                  href='https://www.nuucon.com/'
                                  color='#000000'
                                  style='text-decoration-line: none; color: rgb(0, 0, 0); font-size: 13px'
                                  target='_blank'
                                  data-saferedirecturl='https://www.google.com/url?q=https://www.nuucon.com/&source=gmail&ust=1591181672898000&usg=AFQjCNGIBUlLIXb9E729Sf8KpqedECkV5w'
                                ) www.nuucon.com
                            tr(height='20' style='vertical-align: middle')
                              td(width='28' style='vertical-align: middle')
                                table(cellpadding='0' cellspacing='0' style='vertical-align: -webkit-baseline-middle; font-family: sans-serif')
                                  tbody
                                    tr
                                      td(style='vertical-align: bottom')
                                        img(src='https://res.cloudinary.com/nuucon-io/image/upload/f_auto,w_13/v1591107214/email/assets/place.png' color='#0f0a0b' width='13' style='display: block')
                              td(style='padding: 0px')
                                span(color='#000000' style='font-size: 13px; color: rgb(0, 0, 0)') Jarrestraße 42, 22303 Hamburg
                        p(color='#A1A2A9' style='font-family: sans-serif; font-weight: bold; margin: 0px; color: #a1a2a9; font-size: 13px').
                          nuucon GmbH
                        p(color='#A1A2A9' style='font-family: sans-serif; color: #a1a2a9; margin: 0 0 20px; font-size: 13px; line-height: 18px').
                          Wir erleichtern Dir die Realisierung von Einrichtungsprojekten
                        img(src='https://res.cloudinary.com/nuucon-io/image/upload/f_auto,w_130/v1591107210/email/assets/logo.png' width='130' style='max-width: 128px; display: block')
      div(class='mt-4')
        btn(class='mr-4' block @click='() => openWindow(index)')
          b Vorschau öffnen
</template>

<script lang="ts">
import Group from '@/components/Group.vue'
import { useLocalStorage } from '@vueuse/core'
import { defineComponent } from 'vue'

const DEFAULT_PROFILE: any = {
  givenName: 'Office',
  familyName: 'Monster',
  phone: '040 228 582 1 -',
  mobile: '040 228 582 1 -',
  position: 'Junior Office Monster',
  linkedin: 'https://www.linkedin.com/',
  email: 'monster@nuucon.com',
  instragram: 'https://www.instagram.com/',
  whatsapp: 'https://wa.me/message/1XXXXXXXXXXv',
  imageUrlB: 'Monster.png',
  imageUrlA:
    'https://res.cloudinary.com/nuucon-io/image/upload/f_auto,w_150,ar_1,c_fill,g_face/v1591162726/email/people/',
}

const EmailSignatureView = defineComponent({
  components: { Group },
  setup() {
    const profiles = useLocalStorage('profiles', [{ ...DEFAULT_PROFILE }])

    return {
      profiles,
      openWindow: (index: number) => {
        const myWindow: Window | null = window.open('', `${Math.random()}`, 'width=450,height=300')
        myWindow!.document.write(document.querySelector(`[data-signature-index="${index}"] table.signature`)!.innerHTML)
      },
    }
  },
})
export default EmailSignatureView
</script>
