<template lang="pug">
transition(name='modals')
  modal#helpcenter(v-if='isOpen' size='sm' :static='breakpoints.md' mode='drawer' class='helpcenter' @close='isOpen = false')
    modal-header
      span(class='font-light') {{ $t('Helpcenter') }}
    overlay(:visible='loading')
    div(v-if='!loading' class='flex w-full')
      div
        keep-alive
        div(v-if='$helpcenter.currentId.value === "default" || selectedGroup || !contentAsMap[$helpcenter.currentId.value]' data-test='helpcenter-content')
          content-nav(:data='filteredContent' :selected='$helpcenter.currentId' :placeholder='$t("Search helpcenter...")' @onSelect='$helpcenter.open($event)')
            div(class='my-4')
              a(v-if='selectedGroup' href='#' @click.prevent='$helpcenter.open("")') {{ $t('All questions') }}
        div(v-else)
          a(href='#' class='flex-center flex' data-test-btn='helpcenter-detail-return' @click.prevent='$helpcenter.open($helpcenter.fromId.value || "default")')
            icon(name='arrow-left')
            span {{ $t('back') }}
          div(class='prose mt-4 pb-10' data-test='helpcenter-detail' @click='onClickMarkdownContent')
            h4 {{ contentAsMap[$helpcenter.currentId.value]?.title }}
            markdown(:data='contentAsMap[$helpcenter.currentId.value]?.content || ""')
</template>

<script lang="ts">
import { useCustomBreakpoints, useFetchData } from '../composables'
import { useFeatureFlags } from '../plugins/FeatureFlags'
import { type IHelpcenterPlugin } from '../plugins/Helpcenter'
import { openIntercomChat } from '../utilities/ThirdParty'
import ContentNav from '@/components/ContentNav.vue'
import { type TranslateFn } from '@/plugins/translation'
import Env from '@/utilities/Env'
import { objectify } from 'radash'
import { defineComponent, computed, ref, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export interface IHtmlJsonItem {
  id: string | undefined
  tag: string
  content: string | undefined
  title: string | undefined
  children: IHtmlJsonItem[]
}

const convertHtml = (htmlAsString: string) => {
  const e = document.createElement('div')
  e.innerHTML = htmlAsString
  const iterate: (e: HTMLElement) => IHtmlJsonItem = (e: HTMLElement) =>
    ({
      tag: e.tagName,
      title: e.getAttribute('title'),
      id: e.getAttribute('id'),
      content: e.innerHTML,
      children: e.children ? (Array.from(e.children) as HTMLElement[]).map(iterate) : [],
    }) as IHtmlJsonItem
  return iterate(e).children as IHtmlJsonItem[]
}

const Helpcenter = defineComponent({
  components: { ContentNav },
  setup() {
    const $helpcenter = inject<IHelpcenterPlugin>('$helpcenter')!
    const $route = useRoute()
    const $router = useRouter()
    const $t = inject<TranslateFn>('$t')!
    const $flags = useFeatureFlags()
    const activeItem = ref('a')
    const { data, loading, fetchData } = $flags.ENABLE_HELPCENTER_LOCAL
      ? useFetchData<string>(`/helpcenter.md`, '')
      : useFetchData<string>(
          `https://res.cloudinary.com/nuucon-io/raw/upload/v1663754885/${Env.buildEnv || 'test'}/helpcenter/helpcenter.md`,
          '',
        )

    fetchData()
    const isOpen = computed({ get: () => $helpcenter.isOpen.value, set: (value) => !value && $helpcenter.close() })
    const content = computed(() => convertHtml(data.value!))
    const selectedGroup = computed(() =>
      $route.query.helpcenter && groupIds.value.includes($route.query.helpcenter as string)
        ? $route.query.helpcenter
        : undefined,
    )
    const filteredContent = computed(() =>
      content.value.filter((group) => (selectedGroup.value ? group.id === selectedGroup.value : group)),
    )
    const groupIds = computed(() => content.value.map((item) => item.id))
    return {
      breakpoints: useCustomBreakpoints(),
      loading,
      startTour: (routeName?: string) => {
        $helpcenter.close()
        $router.push(routeName ? { name: routeName!, query: { tour: 'true' } } : { query: { tour: 'true' } })
      },
      onClickMarkdownContent: (e: MouseEvent) => {
        const href = (e.target as HTMLElement).getAttribute?.('href')
        if (href && href.includes('#')) {
          e.preventDefault()
          const a = href.slice(1, href.length).split(':')
          switch (a[0]) {
            case 'application':
              $router.push({ name: a[1] })
              break
            default:
              $helpcenter.open(a[0], $helpcenter.currentId.value)
          }
        }
      },
      filteredContent,
      backToOverview: () => ($helpcenter.open(''), (activeItem.value = 'a')),
      activeItem,
      selectedGroup,
      contentAsMap: computed(() =>
        objectify(
          filteredContent.value.flatMap((group) => group.children),
          (item) => item.id!,
        ),
      ),
      chatWithUs: () => openIntercomChat($t('Hello, hello')),
      isOpen,
    }
  },
})
export default Helpcenter
</script>
