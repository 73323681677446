<template lang="pug">
.row()
  message-list(:messages='$messages.value' @close='(key: string) => $msg.close(key)')
  generic-form(id='FormResetPassword'  endpoint='/_/ResetPassword' :showOverlay='false' class='sm:min-w-[480px]')
    template(#default='{loading, error, currentAction}')
      a(class='link-light' href='/login.html') {{$t('Back to Login')}}

      h2(class='mb-2 mt-8') {{ $t("Reset Password") }}
      form-hidden(name='EnterValidationCode' )
      form-input(ref='autofocus' type='email' name='Email' )
      form-input(name='ValidationCode' :hideWhenDisabled='true' )
      form-input(name='NewPassword' :controlProps='{type:"password"}' :hideWhenDisabled='true' )
      form-input(name='NewPasswordRepeat' :controlProps='{type:"password"}' :hideWhenDisabled='true' )
      form-action(name='Generate' :loading="loading && currentAction === 'Generate'" :error="error" :hideWhenDisabled='true' )
      form-action(name='Validate' :loading="loading && currentAction === 'Validate'" :error="error" :hideWhenDisabled='true' )
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const ResetPasswordView = defineComponent({})
export default ResetPasswordView
</script>
