import { type Placement } from '@floating-ui/dom'
import { type App, type Plugin, computed, ref, type Ref, type ComputedRef } from 'vue'

export type SetTourStateFn = (tourId: string, key: keyof ITourState, value: any) => any

export type TourState = 'pristine' | 'progress' | 'canceled' | 'completed' | 'paused'
export interface ITourState {
  currentIndex: number
  state: TourState
  active: boolean
  tourId: string
  level: number
}

export interface ITourEvent {
  name: string
  value: string
}

export interface ITourStep {
  children?: ITourStepChild[]
  showSkipButton?: boolean
  nextButtonText?: string
  skipButtonText?: string
  text?: string
  video?: string
  title?: string | { text: string; placeholders: () => string[] }
  position?: Placement
  waitForElement?: boolean | string
  target?: string
  padding?: number
  noBackdrop?: boolean
  size?: 'lg' | 'default' | 'sm' | 'md'
  disabled?: () => boolean
  onNext?: (target?: HTMLElement | null) => any
}

export type ITourStepChild = Pick<ITourStep, 'text' | 'video' | 'title'>

export interface ITourPlugin {
  register: (id: string) => void
  unregister: (id: string) => void
  start: (id: string) => void
  stop: (id: string) => void
  availableTours: Ref<string[]>
  activeTours: Ref<string[]>
  activeTour: ComputedRef<string | undefined>
}
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $tour: ITourPlugin
  }
}

export interface IUserTourState {
  [tourId: string]: TourState
}

export const mapObject = <T, F = any>(o: Record<string, T>, fn: (item: T, key: string) => F) =>
  Object.fromEntries(Object.entries(o).map(([key, item]) => [key, fn(item, key)]))

const TourPlugin: Plugin = {
  install(app: App) {
    // tours that have been started
    const activeTours = ref<string[]>([])
    // tours that have been registered and could be started
    const availableTours = ref<string[]>([])
    const tour: ITourPlugin = {
      register: (id: string) => !availableTours.value.includes(id) && availableTours.value.push(id),
      unregister: (id: string) =>
        (availableTours.value = availableTours.value.filter((idInArray: string) => id !== idInArray)),
      start: (id: string) => !activeTours.value.includes(id) && activeTours.value.push(id),
      stop: (id: string) => (activeTours.value = activeTours.value.filter((idInArray: string) => id !== idInArray)),
      activeTours,
      availableTours,
      activeTour: computed<string | undefined>(() => activeTours.value[activeTours.value.length - 1]),
    }
    app.provide<ITourPlugin>('$tour', tour)
    app.config.globalProperties.$tour = tour
  },
}
export default TourPlugin
