<template lang="pug">
modal(size='sm' admin @close='$emit("close")')
  generic-form(:endpoint='`/_/AddOrEditPconProduct/${productId}?projectId=${projectId}&inquiryId=${inquiryId}`' :allowShortcuts='true' @submitSuccess='onSubmitSuccess')
    template(#default='{ config }')
      form-header
        span(v-html='config.label')
      form-input(name='Name')
      form-input-money(name='PriceNet')
      form-footer
        form-action(:primary='true' name='UpdateFields')
</template>

<script setup lang="ts">
import { type IFormResponseInfo } from '@/components/GenericForm/types'
import { useInquiriesStore } from '@/pages/inquiries/store'
import { useProjectsStore } from '@/pages/projects/store'
import { defineProps } from 'vue'

const props = defineProps({
  productId: { type: String, required: true },
  projectId: { type: String, required: true },
  inquiryId: { type: String },
})

const emit = defineEmits(['close'])
const store = useProjectsStore()
const inqStore = useInquiriesStore()
const onSubmitSuccess = (response: IFormResponseInfo) => {
  if (props.inquiryId) {
    inqStore.setInquiry(response.data.inquiry)
  } else {
    store.setProject(response.data.project)
  }
  emit('close')
}
</script>
