<template lang="pug">
modal(size='sm' admin @close='$emit("close")')
  modal-header(:title='$t("Users")' class='!mb-2' @close='$emit("close")')
  div(v-if='items.length' class='bleed !mb-4')
    list-item(v-for='{ salutationString, email, userId } in items' :key='userId' class='leading-tight')
      template(#icon)
        avatar(:username='salutationString' :size='32')
      div(class='font-bold') 
        span {{ salutationString }}
        span(class='font-normal text-text-light') !{ ' ' } ({{ userId }})
      div(class='') {{ email }}
      template(#actions)
        btn(plain icon='trash' :data-tooltip='$t("Benutzer entfernen")' @click='remove(userId)')
  empty-state(v-else centered)
  form-footer
    template(#secondary)
      btn(icon='plus' secondary @click='$modals.open("form-member-add-admin", { id, type })')
        span {{ $t('Add user from system') }}
</template>

<script lang="ts">
import { type MemberType } from './FormMemberAddAdmin.vue'
import { thorFormPostPromise, useOnSuccess } from '@/composables/'
import { useConfirm } from '@/plugins/Confirm'
import { type IMember } from '@/plugins/User'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import { type PropType } from 'vue'
import { onBeforeMount } from 'vue'

const FormMembersAdmin = defineComponent({
  props: {
    id: { type: String, required: true },
    type: { type: String as PropType<MemberType>, required: true },
  },
  setup(props) {
    const items = ref<IMember[]>([])
    const { confirm } = useConfirm()
    const load = async () => {
      items.value = (await axios.get<IMember[]>(`/_/${props.type}/${props.id}/Members`)).data
    }
    useOnSuccess('AddUser', load)
    onBeforeMount(load)
    return {
      remove: (id: string) =>
        confirm({
          onOk: async () => {
            await thorFormPostPromise(`/_/${props.type}/${props.id}/Members/${id}/DeleteMember`, {})
            load()
          },
        }),
      items,
    }
  },
})
export default FormMembersAdmin
</script>
