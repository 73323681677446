<template lang="pug">
modal(size='base' @close='$emit("close")')
  generic-form(
    :endpoint='`/Administration/Brands/${id}`'
    :allowShortcuts='true'
    updateOnSubmitSuccess='AddBrandSupplier,RemoveBrandSupplier,SetAsResponsibleSupplier'
    @submitSuccess='(e) => e.action === "UpdateBrand" && $emit("close")'
  )
    template(#default='{ config, configMap }')
      form-header(@cancel='$emit("close")')
        span {{ $t('Edit brand:') }}
        em(v-html='config.label')
        template(#actions)
          form-action-switch(enabledName='Activate' disabledName='Deactivate')

      form-input(name='Name')
      div(v-if='configMap.BrandSupplierList?.options?.length' class='py-4')
        group(label='Suppliers' bordered class='!pb-0')
          simple-list(:items='configMap.BrandSupplierList.options' style='--list-padding-left: 20px; --list-padding-right: 20px')
            template(#item='{ data }')
              list-item
                span {{ data.label }}
                  span(v-if='configMap.ResponsibleSupplierId.value === data.value' class='ml-2 font-normal text-text-light') {{ $t('Data Supplier') }}
                  span(v-if='configMap.DefaultPriceSupplierId.value === data.value' class='ml-2 font-normal text-text-light') {{ $t('Price Supplier') }}
                template(#actions)
                  btn(icon='trash' faded @click='removeSupplier(data.value)')
      form-tab-group
        form-textarea(name='Description')
      form-checkbox(name='IsEditableByResponsibleSupplier')
      form-checkbox(name='IsStandardDiscountAllowed') 
      form-checkbox(name='ForExternalProducts' class='mb-2') {{ 'Display this brand, while creating or updating external products' }}
      form-footer
        template(#secondary)
          btn(secondary icon='plus' @click.prevent='$utils.routeQueryAdd({ "form-models-add": id })') {{ $t('Create model') }}
          file-upload(:url='`/Administration/Brands/${id}/UploadAsset`' name='Asset' :multiple='false' secondary icon='custom-file-image')
            | {{ $t('Update Logo') }}
        form-action(:primary='true' name='UpdateBrand')
</template>

<script lang="ts">
import Btn from '@/components/Btn.vue'
import { thorFormPostPromise } from '@/composables/'
import { useConfirm } from '@/plugins/Confirm'
import { defineComponent } from 'vue'

const FormBrandsEdit = defineComponent({
  components: { Btn },
  props: {
    id: { type: String, required: true },
  },
  setup(props) {
    const { confirm } = useConfirm()

    return {
      removeSupplier: (id: string) =>
        confirm({
          onOk: () =>
            thorFormPostPromise(`/Administration/Brands/${props.id}/BrandSupplier${id}/RemoveBrandSupplier`, {}),
        }),
    }
  },
})
export default FormBrandsEdit
</script>
