<template lang="pug">
.row(style='max-width: 620px')
  message-list(:messages='$messages.value' @close='(key: string) => $msg.close(key)')
  generic-form#set-initial-password(:endpoint='`/_/SetInitialPassword?User=${user}&Token=${token}`'  :showOverlay='false')
    template(#header)
      h3 {{ $t("Set initial password") }}
      p(class='my-4') {{ $t("Almost done! Set your initial password and log in.") }}
    template(#default='{loading, error}')
      form-hidden(name='User' :modelValue='user' forceValue )
      form-hidden(name='Token' :modelValue='token' forceValue )
      form-input(name='NewPassword' :controlProps='{type:"password"}')
      form-input(name='NewPasswordRepeat' :controlProps='{type:"password"}')
      action-bar(align='left' class='mb-3')
        template(#left)
          form-action(name='SetPassword' medium :loading="loading" :error="error" )
      a(class='link-light' href='/login.html') {{$t('Back to Login')}}
</template>

<script lang="ts">
import ActionBar from '@/components/ActionBar.vue'
import { defineComponent, computed } from 'vue'

const SetInitialPasswordView = defineComponent({
  components: { ActionBar },
  setup() {
    return {
      token: computed(() => new URLSearchParams(window.location.search).get('Token')),
      user: computed(() => new URLSearchParams(window.location.search).get('User')),
    }
  },
})
export default SetInitialPasswordView
</script>
