<template lang="pug">
div(v-if='$translationsReady')
  div(v-if='index == 1')
    generic-form#FormRegistration(
      :endpoint='endpoint'
      :showOverlay='false'
      @submitSuccess='onSubmitSuccess'
    )
      form-select-native(name='Step1/RegistrationTypeField' :modelValue='"customerregistration"')
      form-input(type='email' size='large' name='Step1/Email')
      form-select-native(name='Step1/Country' :modelValue='"DE"')
      form-hidden(name='Step2/Salutation' :modelValue='"notspecified"')
      form-group(name='Step2')
        div(class='sm:grid sm:grid-cols-2 sm:gap-4')
          form-input(size='large' name='GivenName')
          form-input(size='large' name='FamilyName')
        form-input(size='large' type='tel' name='Phone')
      div(v-if='params.registerType && params.sourceId')
        form-hidden(name='Step3/CompanyType' modelValue='Other')
        form-hidden(name='Step3/CompanyName' :modelValue='params.sourceId')
        form-hidden(name='Step3/Website' :modelValue='params.sourceId')
      div(v-else) 
        form-select-native(size='large'  name='Step3/CompanyType')
        form-input(size='large' name='Step3/CompanyName')
        form-input(size='large' name='Step3/Website' :note='$t("You can also provide a link to a relevant social media profile if you do not have a website.","registration")')
      form-checkbox(name='Step3/Confirm')
        span(v-html='$t("I hereby confirm that I have read and agree with the {0} and the {1}.", "registration", agbTranslations )')
      form-action(name='Register' primary large)
        template(#inner='{label}')
          span(class='mr-2') {{label}}
          icon(name='arrow-right' class='text-xl')
      p(class='pt-4 text-text-light text-sm')
        |  {{$t('The nuucon service is for companies and design professionals only.')}}
  div( v-else data-test='registration-success')
    div(class='prose')
      h3 {{$t('Thank you for signing up with nuucon!', 'registration')}}
      p(class='text-lg') {{$t('We have sent you an email with a confirmation link.', 'registration')}}
      p {{$t('If you did not receive an email please check your spam folder or contact our', 'registration')}} !{' '}
        a(v-contact-link='{message: $t("I have trouble with the signup process..")}') {{$t('Support Team', 'registration')}}
        | .
</template>

<script lang="ts">
import { getQueryValue } from '@/utilities/Location'
import { gtmVirtualPageView } from '@/utilities/Gtm'
import { useTranslation } from '@/plugins/translation'
import { defineComponent, onMounted, computed, ref } from 'vue'
import qs from 'qs'

const Registration = defineComponent({
  setup() {
    const index = ref(1)
    const { $t } = useTranslation()
    const params = qs.parse(window.location.href.split('?')?.[1])

    const tellIframeAboutFormHeight = () =>
      (window as any).top.postMessage({ height: document.body.scrollHeight, width: document.body.scrollWidth }, '*')
    // for embed iframe resize
    onMounted(async () => {
      tellIframeAboutFormHeight()
      setInterval(tellIframeAboutFormHeight, 500)
    })

    return {
      endpoint:
        params.sourceId && params.registerType
          ? `/_/Register?sourceId=${params.sourceId}&registerType=${params.registerType}`
          : '/_/Register',
      index,
      params,
      onSubmitSuccess: () => {
        index.value = 2
        gtmVirtualPageView(`${document.title} – Registration – Submitted`, `/registration.html/step-${index.value}`)
      },
      agbTranslations: computed(() => [
        `<a class="decoration" href="https://nuucon.com/terms" target="_blank">${$t(
          'terms of service',
          'registration',
        )}</a>`,
        `<a class="decoration" href="https://nuucon.com/privacy" target="_blank">${$t('Datenschutzrichtlinie')}</a>`,
      ]),
      linkPrivacy: computed(() => [
        `<a target="_blank" href="https://nuucon.com/privacy?from=${getQueryValue('from')}">${$t(
          'Privacy',
          'registration',
        )}</a>`,
      ]),
    }
  },
})
export default Registration
</script>
