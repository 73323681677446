<template lang="pug">
img(v-image-lazy :data-src='src' :data-cloudinary='cdnOptions' class='cdn-image')
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const CdnImage = defineComponent({
  props: {
    src: { type: String, required: true },
    cdnOptions: String,
  },
})
export default CdnImage
</script>
<style lang="stylus">
.cdn-image
  max-width: 100%
  &[rounded]
    border-radius: 10px
</style>
