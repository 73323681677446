<template lang="pug">
dropdown
  template(#default='{ toggle, visible }')
    badge(bordered :visible='store.unseenEntryDates.length > 0' dot :count='store.unseenEntryDates.length' :offset='[10, 10]' purple)
      btn(icon='help-circle' faded large-icon :active='visible' :data-tooltip='$t("Help")' class='btn-help' @click='toggle')
  template(#content)
    dropdown-item(lg icon='custom-chat' :description='$t("Talk to our operators")' @click='$thirdParty.openIntercomChat("")') {{ $t('Talk to us', 'dashboard') }}
    dropdown-item(lg icon='calendar' :description='$t("Make an appointment for a product demo with our support team.")' @click='$thirdParty.makeAppointment()') {{ $t('Make an appointment', 'dashboard') }}
    //- dropdown-item(lg icon='help-circle' href='https://nuucon.com/faq' target='_blank' ) {{$t('Frequently asked questions')}}
    dropdown-item(
      v-if='$flags.ENABLE_HELPCENTER'
      lg
      :description='$t("Get detailed information of the plattform features.")'
      icon='help-circle'
      target='_blank'
      @click='$helpcenter[$helpcenter.isOpen.value ? "close" : "open"]()'
    ) {{ $t('Helpcenter') }}
    dropdown-item(lg icon='bell-01' :description='$t("Get the lates platform updates and news.")' @click='$modals.open("modal-platform-updates")') 
      badge(:visible='store.unseenEntryDates.length > 0' :count='store.unseenEntryDates.length' :offset='[0, -23]' purple)
        span {{ $t("What's new?") }}
</template>

<script setup lang="ts">
import { usePlatformUpdates } from '@/stores'

defineProps({
  tourId: { type: String },
})
const store = usePlatformUpdates()
</script>
