<template lang="pug">
div(v-if='$translationsReady ' )
  message-list(:messages='$messages.value' @close='(key: string) => $msg.close(key)')
  div(style='--overlay-bg: var(--color-body)')
    form-login(v-if='ready' :showOverlay='true' class='mb-3')
      br
      template(#before)
        div(class='')
          h2() {{$t('Login to nuucon')}}
          p(class='mt-4 mb-6 text-xl') {{$t('please enter your email and password to access the nuucon platform')}}
      template(#after)
        div(class='mt-6')
        div(class='flex gap-3')
            a(class='link-faded' href='/registration.html') {{$t('Not registered yet?')}}
            a(class='link-faded' href='/reset-password.html') {{$t('Forgot your password?')}}

</template>

<script lang="ts">
import FormLogin from '@/forms/FormLogin.vue'
import Cookies from 'js-cookie'
import { defineComponent, ref, nextTick } from 'vue'
import { getSessionPublicCookie, logout } from '@/plugins/User'

const LoginView = defineComponent({
  components: { FormLogin },
  setup() {

    Cookies.set('redirectHash', window.location.hash)
    const ready = ref(false)
    const hasSessionPublicCookie = !!getSessionPublicCookie()
    if (hasSessionPublicCookie) {
      // redirect to application when session public cookie exists
      window.location.pathname = '/'
    } else {
      // force logout if session cookie exists but not session public cookie
      logout().subscribe(() => nextTick(() => ready.value = true))
    }
    return {
      ready
    }
  },
})
export default LoginView
</script>
