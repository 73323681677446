<template lang="pug">
dropdown-options
  template(#button='{ toggle, active }')
    btn(:active='active' iconOnly size='small' plain class='dropdownUser' data-test='btn-nav-user' @click='toggle')
      avatar(:username='$user.fullName' :backgroundColor='backgroundColor' :size='28' :icon='$permissions.canImpersonate ? "custom-nuucon" : ""')
  dropdown-item#menu-item-user(icon='user' to='/user') {{ $t('User profile') }}
  dropdown-item#menu-item-customer(icon='custom-company' to='/customer') {{ $t('Company profile') }}
  dropdown-item#dashboard-switch(v-if='$user.supplierId' icon='arrow-left' href='/manufacturer.html')
    span {{ $t('Zu Herstellerdashboard wechseln') }}
  dropdown-item#menu-item-logout(icon='log-out' @click='logout')
    span {{ $t('Log out') }}
  dropdown-item#menu-item-impersonate(v-if='$permissions.canImpersonate' icon='custom-impersonate' class='highlight-nuucon' @click='impersonate')
    | {{ $t('Impersonate user') }}
  dropdown-group(v-if='$permissions.canAccessAdminMenu && flags.length' :title='$t("Feature Flags")' class='[&_>div]:!text-nuucon')
    dropdown-item(v-for='flag in flags' :key='flag.flag' @click='update(flag.flag, !$flags[flag.flag])')
      control-switch(:modelValue='!!$flags[flag.flag]' class='pointer-events-none text-sm' size='tiny') 
        div(class='text-sm text-nuucon') {{ flag.flag }}
</template>

<script setup lang="ts">
import { useFeatureFlagsManipulation } from '@/plugins/FeatureFlags'
import { useUser } from '@/plugins/User'
import { useUtils } from '@/plugins/Utils'
import { computed } from 'vue'

const $utils = useUtils()
const $user = useUser()

const isImpersonating = computed(() => $user.impersonator !== undefined || false)
const backgroundColor = computed(() => (!$user.username ? 'grey' : isImpersonating.value ? '#ce75c8' : undefined))

const logout = () =>
  fetch('/_/Logout').then(() => (isImpersonating.value ? location.reload() : window.location.assign('/')))
const impersonate = () => $utils.routeQueryAdd({ 'form-impersonate': 'open' })
const { flags, update } = useFeatureFlagsManipulation(['ENABLE_PCON_IMAGES_FROM_CANVAS'])
</script>
