<template lang="pug">
generic-form(endpoint='/_/Login' :showOverlay='false')
  slot(name='before')
  form-input(name='Email' type='text' :autofocus='true')
  form-input(name='Password' :controlProps='{ type: "password" }')
  form-action(:name='action' :primary='true' medium)
  slot(name='after')
</template>

<script lang="ts">
import GenericForm from '@/components/GenericForm/GenericForm.vue'
import { defineComponent } from 'vue'

const FormLogin = defineComponent({
  components: { GenericForm },
  props: {
    action: { type: String, default: 'Login' },
  },
})
export default FormLogin
</script>
