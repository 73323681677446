<template lang="pug">
modal(size='sm' @close='$emit("close")')
  generic-form(endpoint='/_/Impersonate' @submitSuccess='handleImpersonateSuccess')
    template(#default='{ config }')
      form-header
        span(v-html='config.label')
      form-select(name='User' :searchable='true' autofocus)
      form-footer
        form-action(name='Impersonate' :primary='true')
</template>

<script lang="ts">
import { defineComponent, type SetupContext } from 'vue'

const FormImpersonate = defineComponent({
  setup(props, context: SetupContext) {
    return {
      handleImpersonateSuccess() {
        context.emit('close')
        setTimeout(() => ((location.hash = ''), location.reload()), 800)
      },
    }
  },
})
export default FormImpersonate
</script>
