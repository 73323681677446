<template lang="pug">
div(class='file')
  div(class='file__inner')
    input(:id='name' ref='el' type='file' :name='name' :accept='accept' :multiple='multiple ? "multiple" : ""' class='file__input' @change='onChange')
    btn(htmlTag='label' :loading='loading' icon='plus' :for='name' v-bind='$attrs')
      slot
        | {{ $t('Upload File') }}
</template>

<script lang="ts">
import Axios from 'axios-observable'
import { defineComponent, ref, type SetupContext } from 'vue'

const FileUpload = defineComponent({
  inheritAttrs: false,
  props: {
    url: { type: String, required: true },
    name: String,
    multiple: Boolean,
    accept: { type: String, default: 'image/*' },
  },
  setup(props, context: SetupContext) {
    let files: FileList | null = null
    const loading = ref(false)
    const el = ref<HTMLInputElement | null>(null)

    const onChange = () => {
      files = el.value!.files
      submit()
    }

    const submit = () => {
      const formData = new FormData()
      if (!files) {
        return
      }

      if (props.multiple) {
        Array.from(files || []).forEach((f: File) => formData.append(props.name!, f))
      } else {
        formData.append(props.name!, (el.value! as HTMLInputElement).files![0])
      }

      loading.value = true
      return Axios.post(props.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (e: any) => context.emit('progress', e),
      }).subscribe({
        next: () => {
          loading.value = false
          context.emit('success')
        },
        error: () => {
          context.emit('error')
          loading.value = false
        },
      })
    }

    return {
      el,
      loading,
      onChange,
      submit,
    }
  },
})
export default FileUpload
</script>
<style lang="stylus">
@import '../styles/variables.styl'

.file
  display: inline-grid
  grid-auto-flow: column
  grid-gap: 3px
  &__input
    height: 0
    overflow: hidden
    width: 0
</style>
