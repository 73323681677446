<template lang="pug">
div(class='app-header')
  div(class='flex items-center')
    div(v-if='$permissions.canAccessAdminMenu' v-tooltip-container class='absolute w-0 opacity-0 transition-all sm:relative' :class='{ "!w-[35px] opacity-100": showAdminMenuButton }')
      btn(
        v-if='showAdminMenuButton'
        class='-ml-2 -mt-1 opacity-0 sm:-ml-4 sm:opacity-100'
        plain
        iconOnly
        :data-tooltip='$t("Show administration menu")'
        data-tooltip-position='right'
        @click='$emit("showAdminMenuButtonClick")'
      )
        icon(class='!text-md' name='more-vertical')
    router-link(:to='{ path: "/", query: { reset: "true" } }' class='app-header__link')
      picture(class='app-header__logo')
        source(srcset='/images/logo.svg' media='(min-width: 600px)')
        img(src='/images/logo-icon.svg')
  div(class='app-header__search') 
    list-filter-input#products(
      v-model='searchTerm'
      name='term'
      data-test='input-product-search'
      :placeholder='breakpoints.sm ? $t("Search Products and Categories..") : $t("Search..")'
      class='!print:hidden'
      @search='onSearch'
    )
  div(class='app-header__nav !print:hidden')
    navigation
      navigation-item(exact to='/' data-test='nav-dashboard') {{ $t('Dashboard') }}
      navigation-item(to='/products' data-test='nav-products') {{ $t('Products') }}
      navigation-item(to='/projects' data-test='nav-projects') {{ $t('Projects') }}
      navigation-item(to='/inquiries' data-test='nav-inquiries') {{ $t('Inquiries') }}
      navigation-item(v-if='$flags.ENABLE_ORDERS' to='/orders' data-test='nav-orders') {{ $t('Aufträge') }}
      nav-btn-help

  div(class='app-header__user !print:hidden')
    nav-btn-clipboard
    user-nav-button
</template>

<script lang="ts">
import { useCustomBreakpoints } from '@/composables/'
import { defineComponent, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const AppHeader = defineComponent({
  props: { showAdminMenuButton: Boolean },
  setup() {
    const $router = useRouter()
    const searchTerm = ref(($router.currentRoute.value.query?.term as string) || '')
    // reset searchTerm when route changes to non-product related page
    watch(
      () => $router.currentRoute.value.name as string,
      (name: string | null | undefined) => !name?.includes('products') && (searchTerm.value = ''),
    )
    return {
      breakpoints: useCustomBreakpoints(),
      searchTerm,
      onSearch: (value: string) => {
        $router.push({ name: '/products/', query: { term: value } }).catch(() => {})
      },
    }
  },
})

export default AppHeader
</script>
<style lang="stylus">
@import '../styles/variables.styl'

.app-header
  background: transparent
  height: auto
  display: grid
  padding-bottom: 40px
  grid-template-columns: auto 1fr auto auto
  width: 100%
  align-items: center
  padding-top: 20px
  @media (max-width: $breakpoint-sm-max)
    padding-top: 15px
    padding-bottom: 10px
    grid-template-rows: auto auto

  &__logo
    margin: 0
    display: inline-block
    height: auto
    img
      max-width: 120px
      max-height: 34px

  &__search
    flex-grow: 1
    grid-column: 2 / span 1
    padding: 0 10px
    @media (min-width: $breakpoint-md)
      padding: 0 30px

  &__user
    display: flex
    margin-right: -10px
    align-items: center
    grid-column: 4 / span 1
    @media (max-width: $breakpoint-sm-max)
      grid-column: 3 / span 2

  &__nav
    grid-column: 3 / span 1
    @media (max-width: $breakpoint-sm-max)
      grid-column: 1 / span 4
      grid-row: 2 / span 1
      overflow: auto
      margin: 10px -15px 0px -15px
      padding: 10px 10px 20px
      width: calc(100% + 15px)
</style>
