<template lang="pug">
div(class='project-message-item-container')
  router-link(v-if='project' :to='linkRoute' class='project-message-item' @click='$emit("close")')
    project-image(:size='60' :project='project' class='roundex-xl')
    div(v-if='inquiry')
      p(class='project-message-item__text')
        b {{ $t('Successfully added to inquiry:') }}
      a(class='project-message-item__link') {{ inquiry.id }}
    div(v-else)
      p(class='project-message-item__text')
        b {{ $t(created ? 'Successfully created project:' : 'Successfully added to project:') }}
      a(class='project-message-item__link') {{ project.title }}
    div
      icon(name='arrow-right' class='project-message-item__icon')
</template>

<script lang="ts">
import { type IProjectData } from '../types'
import { type IInquiry } from '@/pages/inquiries/types'
import { useModals } from '@/plugins/Modals'
import { computed, defineComponent, type PropType } from 'vue'
import { type RouteLocationRaw } from 'vue-router/auto'

const ProjectMessageItem = defineComponent({
  props: {
    project: { type: Object as PropType<IProjectData>, required: true },
    inquiry: Object as PropType<IInquiry>,
    created: Boolean,
  },
  setup(props) {
    const $modals = useModals()
    return {
      linkRoute: computed<RouteLocationRaw>(() =>
        !props.inquiry
          ? `/projects/${props.project.id}/`
          : ($modals.getLocation('form-inquiry', {
              projectId: props.project.id,
              inquiryId: props.inquiry!.id,
            }) as RouteLocationRaw),
      ),
    }
  },
})

export default ProjectMessageItem
</script>
<style lang="stylus">
@import '../../../styles/variables.styl'

.project-message-item
  flex-grow: 1
  padding-top: 5px
  align-items: center
  grid-template-columns: 60px 1fr
  @media (min-width: $breakpoint-sm)
    grid-template-columns: 60px 1fr 40px
  grid-auto-flow: column
  grid-gap: 18px
  display: grid
  &:hover
    text-decoration: none
  &:active
    text-decoration: none
  &__text
    line-height: 1.2
    font-size: 14px
    margin: 0!important
    color: var(--color-text)
    @media (min-width: $breakpoint-sm)
      font-size: 15px
  &__link
    ~/:hover &
      text-decoration: underline
  &__icon
    font-size: 28px
    color: var(--grey-300)
    @media (max-width: $breakpoint-xs-max)
      display: none!important
    ~/:hover &
      color: var(--grey-400)
</style>
