<template lang="pug">
modal-with-slider(v-model='visibleModel' data-test='modal-onboarding' @close='$emit("close")')
  modal-with-slider-item(:id='0') 
    template(#header)
      div(class='flex justify-center')
        img(src='/images/onboarding/onboarding-start.png' class='w-full' style='aspect-ratio: 1.5')
    h4(class='thin') Hallo <b>{{ $user.fullName }}</b>, willkommen auf der nuucon Plattform!
    p Verwalte Deine Einrichtungsprojekte: Produktsuche, Bedarfslisten, Produktvorschläge und Bestellungen. Wir unterstützen Dich in jedem Schritt des Einkaufsprozesses.
  modal-with-slider-item(:id='1') 
    template(#header)
      div(class='flex justify-center')
        img(src='/images/onboarding/onboarding-catalog.png' class='w-full' style='aspect-ratio: 1.5')
    h4 Produktkatalog
    p Hast Du schon ein konkretes Produkt im Kopf? Oder möchtest Du lieber erst einmal in einer bestimmten Kategorie stöbern? In unserem Produktkatalog sind über 50.000 Produkte und mehrere hundert Marken aufgelistet.
  modal-with-slider-item(:id='2') 
    template(#header)
      div(class='flex justify-center')
        img(src='/images/onboarding/onboarding-project.png' class='w-full' style='aspect-ratio: 1.5')
    h4 Projekte
    p Projekte ermöglichen es Dir, Produkte aus dem Produktkatalog sowie von anderen Quellen (Herstellerseiten, Online-Shops usw.) zu sammeln, zu strukturieren und anzufragen.
  modal-with-slider-item(:id='3') 
    template(#header)
      div(class='flex justify-center')
        img(src='/images/onboarding/onboarding-inquiry.png' class='w-full' style='aspect-ratio: 1.5')
    h4 Anfragen
    p In einer Anfrage kannst Du Mengenangaben und Lieferoptionen für die Produkte angeben und bearbeiten, die aus einem Projekt angefragt wurden. Diese werden unmittelbar in der Preiskalkulation berücksichtigt und Du kannst ein unverbindliches Angebot anfragen.
</template>

<script setup lang="ts">
import ModalWithSlider from '@/components/ModalWithSlider.vue'
import ModalWithSliderItem from '@/components/ModalWithSliderItem.vue'

const visibleModel = defineModel<boolean>()
defineEmits(['close'])
</script>
