<template lang="pug">
div(class='-mr-2 flex items-center gap-0')
  span {{ $t('Project possibly out of sync!') }}
  btn(link icon='rotate-cw' @click='reload') {{ $t('reload') }}
</template>

<script lang="ts">
import { useProjectsStore } from '../store'
import { defineComponent } from 'vue'

const ProjectMessageErrorItem = defineComponent({
  props: {
    projectId: { type: String, required: true },
  },
  setup(props) {
    const store = useProjectsStore()
    return {
      reload: () => store.loadProject(props.projectId),
    }
  },
})

export default ProjectMessageErrorItem
</script>
